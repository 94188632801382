import { $fetch, FetchError } from "ofetch";
import { parseCookies } from "h3";

const CSRF_COOKIE = "XSRF-TOKEN";
const CSRF_HEADER = "X-XSRF-TOKEN";

export const $larafetch = $fetch.create({
	credentials: "include",
	onRequestError(error) {
		console.log(error);
	},
	async onRequest({ request, options }) {
		const { backendUrl, frontendUrl } = useRuntimeConfig().public;
		const event = typeof useEvent === "function" ? useEvent() : null;
		let token = event
			? parseCookies(event)[CSRF_COOKIE]
			: useCookie(CSRF_COOKIE).value;

		if (
			process.client &&
			["post", "delete", "put", "patch"].includes(
				options?.method?.toLowerCase() ?? ""
			)
		) {
			token = await initCsrf();
		}

		let headers: any = {
			accept: "application/json",
			...options?.headers,
			...(token && { [CSRF_HEADER]: token }),
		};

		if (process.server) {
			const cookieString = event
				? event.headers.get("cookie")
				: useRequestHeaders(["cookie"]).cookie;

			headers = {
				...headers,
				...(cookieString && { cookie: cookieString }),
				referer: frontendUrl,
			};
			options.baseURL = "https://vispauto.com/";
		}

		options.headers = headers;
	},
	async onResponseError({ response }) {
		const status = response.status;
		if ([500, 422].includes(status)) {
			console.error("[Laravel Error]", response.statusText, response._data);
		}
	},
});

async function initCsrf() {
	if (process.server) {
		return;
	}

	const { backendUrl } = useRuntimeConfig().public;
	const existingToken = useCookie(CSRF_COOKIE).value;

	if (existingToken) return existingToken;

	try {
		await $fetch("/sanctum/csrf-cookie", {
			credentials: "include",
		});
		await new Promise((resolve) => setTimeout(resolve, 100));
		return useCookie(CSRF_COOKIE).value;
	} catch (error) {
		console.error("Failed to initialize CSRF token:", error);
	}
}
